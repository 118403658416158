import React from 'react';
import styled from 'styled-components';

function PrivacyContent(props) {
  return (
    <Wrapper>
      <h2>隱私權政策</h2>
      <div className="info">
        歡迎來到Bakershell.com.tw（以下簡稱本網站），我們致力提供給您最好的產品與服務，為了讓您安心使用本網站的所有服務與資訊，特此告知您本網站的隱私權保護政策，以利保障您的權益，請您詳閱下列內容：
        <h3>1. 個人資料使用的規範</h3>
        <div>
          本網站對於會員個人資料的收集，遵守中華民國『電腦處理個人資料保護法』之規範。
        </div>
        <h3>2. 資料搜集之目的、方式及利用範圍</h3>
        <div style={{paddingLeft: 10}}>
          <div>
            (a)
            本網站為行銷、消費者保護、消費者/客戶管理與服務及其他電子商務服務、帳務管理、契約／類似契約關係或其他法律關係事務、調查/統計與研究分析、資訊與資料庫管理、非公務機關依法定義務所進行個人資料之搜集處理及利用等目的，搜集並於前述目的範圍內處理及利用您的個人資料。
          </div>
          <div>
            (b)
            當您註冊本網站帳號、使用產品及服務、流覽本網站或某些合作夥伴的網頁，以及參加宣傳行銷活動時，本網站將會收集您的個人資料。本網站也可能將商業夥伴或關係企業所提供您的資訊與本網站所擁有您的個人資料相結合，或與商業夥伴或關係企業共用您的個人資料。
          </div>
          <div>
            (c)
            當您在本網站註冊時，需填寫使用者姓名、電子郵件、手機號碼等資料。您得自由選擇是否提供個人資料給我們，但若特定資料欄位系屬必填欄位者，您若不提供該等資料則無法使用相關的產品及服務。
          </div>
          <div>
            (d)
            本網站收集您與我們或我們的商業夥伴間交易的資料，包括您使用本網站所提供產品及服務的相關資料。
          </div>
          <div>
            (e)
            本網站會自動接收並紀錄您所電腦和流覽器上的資料，包括IP位址、本網站cookie中的資料及您流覽的網頁紀錄。
          </div>
          <div>
            (f)
            在您使用本網站產品及服務期間，本網站得以全球地區將資料用作以下用途：客制化廣告及您看到的網頁內容、滿足您對產品及服務的要求、改進產品及服務、客戶聯繫、進行研究，以及提供內部及外部客戶不載有您個人資料之市場分析或業務報告。
          </div>
          <div>
            (g)
            本網站搜集、處理及利用個人資料時，皆遵循本網站之個人資料管理目標與政策為指導原則，並訂有完善之個人資料保護安全維護計畫，因此您可以安心的將個人資料提供予本網站，本網站絕不販賣給本網站以外的其他人及單位，並且嚴禁內部人員私自使用這些資料。本網站將會嚴密的保護所搜集到的客戶個人資料，任何人必須經過合理的授權，才能夠在適當的情況下使用這些資料，並且本網站將保留相關的使用紀錄。
          </div>
        </div>
        <h3>3. 資料分享與揭露</h3>
        <div>
          除非是為了向您提供您所要求的產品或服務、獲得您的授權，或有以下情形，否則本網站不會向任何其他人士或非關係企業提供您的個人資料，或與之分享：
        </div>
        <div style={{paddingLeft: 10}}>
          <div>
            (a)
            我們向已簽署保密條款之代表本網站執行業務或與我們值得信賴的合作夥伴提供這些資料。這些公司可能會將您的個人資料用於協助本網站就我們和行銷夥伴所提供產品及服務的事宜，與您進行溝通。但這些公司無獨立權利分享這些資料；
          </div>
          <div>
            (b)
            為了符合適用法律、法規、法律程式或政府執行命令以及在法律規定或合法範圍內，為了保護本網站、使用者或公眾權利、財產或安全不致遭受危害；
          </div>
          <div>
            (c)
            我們亦對傳票、法院命令或法律程式作出回應、取得或行使法律權利，或對訴訟上之請求提出防禦。嗣後若發生任何爭議而有訴訟的必要時，雙方同意之管轄法院為臺灣臺北地方法院；
          </div>
          <div>
            (d)
            我們認為為了調查和防止非法活動、涉嫌詐欺、對人身安全有潛在威脅的狀況、對本網站服務條款的違反，或為了對上述情形採取應對措施，或法律另有規定，而有必要分享您的個人資料時；
          </div>
          <div>
            (e)
            如果本網站全部或部分業務被其他公司收購或合併，我們會將您的個人資料移轉給該公司。如果發生這種情況，本網站會在您的個人資料被移轉且將適用不同的隱私權政策前進行通知。
          </div>
        </div>
        <h3>4. 您對個人資料的權益</h3>
        <div>
          依據個資法第3條規定，您就本網站所保有之己身個人資料得行使下列權利：
        </div>
        <div style={{paddingLeft: 10}}>
          <div>
            (a)
            得向本網站查詢、請求閱覽或請求制給複製本，而本網站依法得酌收必要成本費用；
          </div>
          <div>(b) 得向本網站請求補充或更正，惟依法您應為適當之釋明；</div>
          <div>(c) 得向本網站請求刪除、停止搜集、處理或利用。</div>
          <div>(d) 在符合法律規定情形下，本網站得不依您請求為之。</div>
        </div>
        <h3>5. 資訊安全</h3>
        <div>
          本網站會採取適當的安全措施，來防止未經授權的資料存取、竄改、披露或損毀，其中包括就資料的搜集、儲存、處理慣例和安全措施進行內部審查，以及實體的安全措施，以防止本網站儲存個人資料的系統遭到未經授權的存取。但網際網路資料的傳輸不能保證百分之百的安全，儘管本網站努力保護您的個人資料安全，然因資料傳輸過程牽涉您上網環境保全之良窳，本網站無法確信或保證您傳送或接收本公司服務平臺資料的安全，您仍須注意並承擔資料傳輸之風險
        </div>
        <h3>6. Cookie之使用</h3>
        <div>
          為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行
          。
        </div>
        <h3>7. 自我保護措施</h3>
        <div>
          請您妥善保管密碼及任何個人資料，不要將任何個人資料，尤其是密碼提供給任何人。在您完成需要登入會員才能使用的服務平臺功能後，請務必做會員註銷動作，若您與他人共用電腦或使用公共電腦，務必要關閉流覽器視窗，以防止他人讀取您的信件或任何個人資料。
        </div>
        <h3>8. 聯絡方式</h3>
        <div>
          若您對於我們說明的政策上任何問題或建議，歡迎來信到我們的客服信箱。
        </div>
        <h3>9. 隱私權政策聲明生效與修改</h3>
        <div>
          本政策之訂立、修改、更新及最終解釋權均屬想食數為股份有限公司所有。以上政策於2022年05月11日公佈並生效
        </div>
        <div>
          本網站有權隨時更新本隱私政策，我們鼓勵用戶經常查看本頁面的任何更改，以了解我們如何幫助保護我們收集的個人信息。您承認並同意您有責任定期查看本隱私政策並了解修改內容。
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h2 {
    margin-bottom: 20px;
  }

  & div {
    margin-bottom: 5px;
  }

  & h3 {
    margin-top: 15px;
  }
`;

export default PrivacyContent;
