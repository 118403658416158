import React from 'react';
import styled from 'styled-components';
import {Button, Input, Form, Checkbox, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import CountdownTimer from 'rev.sdk.js/Components/CountdownTimer';
import PrivacyContent from '../../Components/PrivacyContent';
import TermsContent from '../../Components/TermsContent';

function RequestPage(props) {
  const [actions] = useOutlet('actions');
  const [requestResult, setRequestResult] = React.useState(null);
  const [endTime, setEndTime] = React.useState(0);
  const [checked, setChecked] = React.useState(false);

  const onFinish = async (values) => {
    const {username} = values;

    if (!checked) {
      message.error('請勾選最下方同意欄位！');
      window.scrollTo({top: 10000, behavior: 'smooth'});
      return;
    }

    try {
      actions.setLoading(true);
      await User.registerRequest(username);
      setEndTime(new Date().getTime() + 2 * 60 * 1000);
      setRequestResult(true);
    } catch (ex) {
      console.log('EX', ex);
      setRequestResult(false);
    } finally {
      actions.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const disableInput = requestResult === true;

  const onConfirmChange = (e) => {
    setChecked(e.target.checked);
  };

  const scrollToSection = (sectionId) => {
    const elem = document.getElementById(sectionId);
    if (elem) {
      window.scrollTo({top: elem.offsetTop - 80, behavior: 'smooth'});
    }
  };

  return (
    <Wrapper>
      <div className="content">
        <h2 style={{marginBottom: 10}}>註冊新帳號</h2>

        {requestResult === true && (
          <div
            style={{
              padding: 15,
              borderRadius: 6,
              backgroundColor: '#eee',
              marginBottom: 15,
            }}>
            <h3 style={{marginBottom: 10}}>成功</h3>
            <p>請到您申請的信箱點擊認證連結, 完成註冊流程!</p>
          </div>
        )}

        {requestResult === false && (
          <div
            style={{
              padding: 15,
              borderRadius: 6,
              backgroundColor: '#eee',
              marginBottom: 15,
            }}>
            <h3 style={{marginBottom: 10}}>失敗</h3>
            <p>註冊請求失敗, 請確認是否您已使用此 EMAIL 註冊過</p>
          </div>
        )}

        <Form
          layout="vertical"
          name="register"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            label="EMAIL"
            name="username"
            rules={[
              {
                required: true,
                message: '請輸入EMAIL!',
              },
              {type: 'email', message: '請確認您的電子信箱格式'},
            ]}>
            <Input disabled={disableInput} />
          </Form.Item>

          <CountdownTimer
            endTimeMs={endTime}
            renderText={(secRemaining) => {
              return `請於 ${secRemaining} 秒內完成認證`;
            }}
            style={{textAlign: 'right'}}>
            <Form.Item style={{textAlign: 'right'}}>
              <Button type="primary" htmlType="submit">
                {requestResult === true ? '重傳認證信' : '註冊'}
              </Button>
            </Form.Item>
          </CountdownTimer>

          <div className="policy-note">
            <p>
              當您完成註冊，即表示您已同意
              <a
                href="#privacy"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('privacy');
                }}>
                隱私權政策
              </a>
              。
            </p>
          </div>

          <div className="policy-content">
            <div id={'privacy'} />
            <PrivacyContent />
          </div>

          <div className="confirm">
            <Checkbox name="checked" value={checked} onChange={onConfirmChange}>
              我已閱讀並同意隱私權政策。
            </Checkbox>
          </div>
        </Form>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  padding-bottom: 60px;
  background-color: #eee;
  min-height: 100vh;

  & > .content {
    max-width: 600px;
    margin: 20px auto;
    padding: var(--basePadding);

    & .policy-note {
      & > p {
        text-align: right;
      }
    }

    & .policy-content {
      background-color: white;
      padding: 20px;
      margin-top: 30px;
      border-radius: 6px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
      transition: all 200ms;
      &:hover {
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.6);
      }
    }

    & .confirm {
      margin-top: 20px;
    }

    & #privacy,
    & #terms {
      transform: translateY(calc(-1 * var(--topNavBarHeight)));
    }
  }
`;

export default RequestPage;
